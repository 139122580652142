<template>
  <div>
    <UserTopNav />

    <v-card class="my-10 pa-5">
      <template v-for="(alarm, i) in data_alarm">
        <v-row justify="center" :key="'row-' + i">
          <v-col cols="12" lg="4">
            <!-- Site ID -->
            <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Site ID</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].site_name"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Site Name -->
            <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Site Name</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].site_name"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Technology -->
            <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Technology</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].technology"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Device -->
            <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Device</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].device"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Category -->
            <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Category</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].category"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Severity -->
            <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Severity</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].severity"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="4">
            <!-- Status -->
            <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Status</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].status"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Created Time -->
            <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Created Time</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].created_at"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Province -->
            <!-- <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Province</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].province"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row> -->
            <!-- City -->
            <!-- <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>City</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].city"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row> -->
            <!-- District -->
            <!-- <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>District</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].district"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row> -->
            <!-- Sub-District -->
            <!-- <v-row justify="center">
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Sub-District</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="data_alarm[i].sub_district"
                  solo
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row> -->
          </v-col>
          <v-col cols="12" lg="4">
            <!-- Description -->
            <v-row>
              <v-col cols="12" lg="4">
                <div class="font-weight-bold mt-2">
                  <h4>Description</h4>
                </div>
              </v-col>
              <v-col cols="12" lg="8">
                <v-textarea
                  v-model="data_alarm[i].description"
                  solo
                  dense
                  autofocus
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="my-5 divider-card" :key="'divider-' + i"></v-divider>
      </template>
      <v-card-actions>
        <v-row dense>
          <v-col cols="6" lg="3"> </v-col>
          <v-spacer></v-spacer>
          <v-btn color="info lighten-2" @click="dialog_confirmation = true">
            Update Alarm
          </v-btn>
        </v-row>

        <!-- DIALOG ADD CONFIRMATION -->
        <v-dialog v-model="dialog_confirmation" max-width="500" persistent>
          <v-card>
            <v-card-title class="text-h5"> Apakah kamu yakin? </v-card-title>

            <v-card-text>
              Dengan menekan tombol "Update" maka alarm akan diselesaikan.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error lighten-2"
                class="my-2 mr-3"
                @click="dialog_confirmation = false"
              >
                Batal
              </v-btn>

              <v-btn color="info lighten-2" @click="submit"> Update </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- LOADING BAR -->
        <v-overlay :value="overlay" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import UserTopNav from "../../../components/UserTopNav.vue";
import JwtServices from "../../../services/jwt.services";

export default {
  name: "submit-alarm-create-form",
  components: { UserTopNav },
  data: () => ({
    user: null,

    data_alarm: [],
    dialog_confirmation: false,
    overlay: false,
  }),
  mounted() {
    this.user = JwtServices.getUser();

    this.data_alarm = this.$store.getters.DataAlarm;

    // Redirect if there is nothing to update
    if (this.data_alarm.length == 0) {
      this.$router.push({ name: "Update Status Alarm" });
    }
  },
  methods: {
    submit() {
      this.overlay = true;

      let data = {
        alarm_data: JSON.stringify(this.data_alarm),
      };

      fetch(
        process.env.VUE_APP_API_URL +
          `api/v1/${this.user.language}/alarm/update`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + this.user.token,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          this.overlay = false;
          if (result.error == true) {
            this.$swal("Opps", result.message, "error");
            return;
          }

          this.dialog_confirmation = false;
          this.$swal({
            title: "Success",
            text: result.message,
            timer: 1000,
            showConfirmButton: false,
            icon: "success",
          });
          this.$router.push({ name: "History Alarm" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.divider-card {
  border-color: rgba(0, 0, 0, 1) !important;
}

.button-add-alarm-form {
  border: 2px solid black;
  border-color: #75ccff !important;
}

.button-remove-alarm-form {
  border: 2px solid black;
  border-color: #ff8c84 !important;
}
</style>